export const updateQueryURL = (filters) => {
  // Sanitize the query string
  const queryString = `
    name=${encodeURIComponent(filters.name || "")}&
    description=${encodeURIComponent(filters.description || "")}&
    segment_description=${encodeURIComponent(filters.segmentDescription || "")}&
    ${
      filters.qualityLabels
        ? filters.qualityLabels
            .map((label) => `quality_labels=${encodeURIComponent(label)}&`)
            .join("")
        : ""
    }&
    ${
      filters.utilityLabels
        ? filters.utilityLabels
            .map((label) => `utility_labels=${encodeURIComponent(label)}&`)
            .join("")
        : ""
    }&
    ${
      filters.infoFields
        ? filters.infoFields
            .map(
              (field) =>
                `info_fields=${encodeURIComponent(JSON.stringify(field))}&`
            )
            .join("")
        : ""
    }&
    page=${encodeURIComponent(filters.page || 1)}
  `.replace(/\s/g, "");

  const qParams = new URLSearchParams(queryString);
  [...qParams.entries()].forEach(([key, value]) => {
    if (!value) {
      qParams.delete(key);
    }
  });

  return `/?${String(qParams)}`;
};
