import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";

import styles from "./TableStyles";

export default function CustomTableHeading({ selectedCols }) {
  return (
    <TableHead>
      <TableRow
        sx={{
          "& td": {
            border: 0,
            padding: 0,
          },
        }}
      >
        <TableCell
          style={{ ...styles.stickyCellLeft, zIndex: 10 }}
          align="left"
        >
          Acquisition
        </TableCell>
        {selectedCols.includes("Ticket") && (
          <TableCell align="left" style={styles.rowTicketStyles}>
            <Tooltip title="This is the jira ticket associated with the acquisition.">
              <div style={styles.cellHeadingDivStyles}>
                Ticket
                <HelpIcon style={{ width: "1rem" }} />
              </div>
            </Tooltip>
          </TableCell>
        )}
        {selectedCols.includes("Team") && (
          <TableCell align="left" style={styles.rowTeamStyles}>
            <Tooltip title="The team that requested the acquisition.">
              <div style={styles.cellHeadingDivStyles}>
                Team
                <HelpIcon style={{ width: "1rem" }} />
              </div>
            </Tooltip>
          </TableCell>
        )}
        {selectedCols.includes("Requester Email") && (
          <TableCell align="left" style={styles.rowEmailStyles}>
            <Tooltip title="The email of the individual that either took the acquisition or requested it.">
              <div style={styles.cellHeadingDivStyles}>
                Requester Email
                <HelpIcon style={{ width: "1rem" }} />
              </div>
            </Tooltip>
          </TableCell>
        )}
        {selectedCols.includes("Tags") && (
          <TableCell align="left" style={styles.rowTagStyles}>
            <Tooltip title="These are string values associated with an acquisition.">
              <div style={styles.cellHeadingDivStyles}>
                Tags
                <HelpIcon style={{ width: "1rem" }} />
              </div>
            </Tooltip>
          </TableCell>
        )}
        {selectedCols.includes("Description") && (
          <TableCell align="left" style={styles.rowDescriptionStyles}>
            Description
          </TableCell>
        )}
        {selectedCols.includes("Radars") && (
          <TableCell align="left" style={styles.rowRadarStyles}>
            Radars
          </TableCell>
        )}
        {selectedCols.includes("Cameras") && (
          <TableCell align="left" style={styles.rowCameraStyles}>
            Cameras
          </TableCell>
        )}
        {selectedCols.includes("Lidars") && (
          <TableCell align="left" style={styles.rowLidarStyles}>
            Lidars
          </TableCell>
        )}
        {selectedCols.some((el) => el.includes("Segment")) && (
          <TableCell align="left" style={{ padding: 0 }}>
            <Table>
              <TableHead sx={{ "& td": { border: 0 } }}>
                <TableRow sx={{ "& th": { border: 0 } }}>
                  {selectedCols.includes("Segment Name") && (
                    <TableCell align="left" style={styles.segNameStyles}>
                      Segment Name
                    </TableCell>
                  )}
                  {selectedCols.includes("Segment Path") && (
                    <TableCell align="left" style={styles.segPathStyles}>
                      <Tooltip title="This is the path where the segment is stored.">
                        <div style={styles.cellHeadingDivStyles}>
                          Segment Path
                          <HelpIcon style={{ width: "1rem" }} />
                        </div>
                      </Tooltip>
                    </TableCell>
                  )}
                  {selectedCols.includes("Segment Description") && (
                    <TableCell align="left" style={styles.segDescriptionStyles}>
                      Segment Description
                    </TableCell>
                  )}
                  {selectedCols.includes("Segment Location") && (
                    <TableCell align="left" style={styles.segLocationStyles}>
                      Location
                    </TableCell>
                  )}
                  {selectedCols.includes("Segment Duration") && (
                    <TableCell align="left" style={styles.segDurationStyles}>
                      Duration (secs)
                    </TableCell>
                  )}
                  {selectedCols.includes("Segment Distance") && (
                    <TableCell align="left" style={styles.segDistanceStyles}>
                      Distance (meters)
                    </TableCell>
                  )}
                  {selectedCols.includes("Segment Quality Labels") && (
                    <TableCell align="left" style={styles.segQualitiesStyles}>
                      <Tooltip title="These are string values associated with a segment.">
                        <div style={styles.cellHeadingDivStyles}>
                          Quality Labels
                          <HelpIcon style={{ width: "1rem" }} />
                        </div>
                      </Tooltip>
                    </TableCell>
                  )}
                  {selectedCols.some((el) => el.includes("Job")) && (
                    <TableCell align="left" style={{ padding: 0 }}>
                      <Table>
                        <TableHead sx={{ "& td": { border: 0 } }}>
                          <TableRow sx={{ "& th": { border: 0 } }}>
                            {selectedCols.includes("Job Type") && (
                              <TableCell
                                align="left"
                                style={styles.jobTypeStyles}
                              >
                                Job type
                              </TableCell>
                            )}
                            {selectedCols.includes("Job Date") && (
                              <TableCell
                                align="left"
                                style={styles.jobDateStyles}
                              >
                                Date
                              </TableCell>
                            )}
                            {selectedCols.includes("Job Git Sha") && (
                              <TableCell
                                align="left"
                                style={styles.jobGitShaStyles}
                              >
                                Git SHA
                              </TableCell>
                            )}
                            {selectedCols.includes("Job Output Path") && (
                              <TableCell
                                align="left"
                                style={styles.jobPathStyles}
                              >
                                Output Path
                              </TableCell>
                            )}
                            {selectedCols.includes("Job Utility Labels") && (
                              <TableCell
                                align="left"
                                style={styles.segQualitiesStyles}
                              >
                                <Tooltip title="These are string values associated with a job.">
                                  <div style={styles.cellHeadingDivStyles}>
                                    Utility Labels
                                    <HelpIcon style={{ width: "1rem" }} />
                                  </div>
                                </Tooltip>
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
            </Table>
          </TableCell>
        )}
        <TableCell
          style={{
            ...styles.stickyCellRight,
            ...styles.optionStyles,
            zIndex: 10,
          }}
          align="center"
        />
      </TableRow>
    </TableHead>
  );
}
