import { useState } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { useEffect } from "react";

export default function EditableInputField(props) {
  const {
    value, // Stores what is visible in the UI
    setValue,
    saveError, // Any error that occurs when saving the 'value'
    handleOk = () => {}, // Fuction to run after clicking 'ok'
    handleCancel = () => {}, // Functino to run after clicking 'cancel'
    savedValue = "", // Stores what portion of 'value' is actually saved
    handleOkOnBlur = true,
    multiline = false,
    maxLength = 300,
    placeholder = " — ",
    disabled = false,
    inputProps = null,
  } = props;

  const [focused, setFocused] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const handleBlur = (e) => {
    // relatedTarget is the element that caused the
    // onBlur to fire. If the relatedTarget is not contained
    // within the currentTarget (currentTarget is this component)
    // then we set Focused to False
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setFocused(false);
      if (handleOkOnBlur) {
        handleIconOk();
      }
    }
  };

  // On press enter
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleOk();
    }
  };

  const handleIconOk = () => {
    if (!validationError) {
      setFocused(false);
      handleOk();
    }
  };

  const handleIconCancel = () => {
    setFocused(false);
    setValue(savedValue); // Since canceled, we revert UI to last saved value
    handleCancel();
  };

  useEffect(() => {
    if (value && value.length > maxLength) {
      setValidationError(`Max length is ${maxLength}`);
    } else {
      setValidationError(null);
    }
  }, [value, maxLength]);

  return (
    <div
      style={containerStyles}
      onFocus={() => setFocused(true)}
      onBlur={handleBlur}
    >
      <StyledTextField
        value={value}
        multiline={multiline}
        size="small"
        placeholder={placeholder}
        autoComplete="off"
        disabled={disabled}
        InputProps={inputProps}
        fullWidth
        error={validationError || saveError}
        helperText={validationError || saveError}
        id="fullWidth"
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {focused || saveError || validationError ? (
        <Stack
          style={stackStyles}
          direction="row"
          spacing="0.2rem"
          justifyContent="flex-end"
        >
          <Box sx={boxStyles}>
            <IconButton
              sx={iconButtonStyles}
              fontSize="small"
              onClick={handleIconOk}
            >
              <CheckIcon style={iconStyles} />
            </IconButton>
          </Box>
          <Box sx={boxStyles}>
            <IconButton
              sx={iconButtonStyles}
              fontSize="small"
              onClick={handleIconCancel}
            >
              <ClearIcon style={iconStyles} />
            </IconButton>
          </Box>
        </Stack>
      ) : null}
    </div>
  );
}

// Styling
const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "grey",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1976d2",
      borderWidth: 1,
    },
  },
});

const containerStyles = {
  position: "relative",
};

const stackStyles = {
  position: "absolute",
  right: "0",
  bottom: "-2.2rem",
  zIndex: 10,
};

const boxStyles = {
  boxShadow: 1,
  background: "#f5f5f5",
};

const iconButtonStyles = {
  height: "1rem",
  width: "1rem",
  borderRadius: "0.2rem",
  padding: "1rem",
  background: "#f5f5f5",
};

const iconStyles = {
  width: "1rem",
};
