import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import SelectableList from "./reusable/SelectableList";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";

import Popover from "./reusable/Popover";

const TYPE_TO_COMPARISON_OPERATORS = {
  float: ["<", "<=", ">", ">=", "=="],
  int: ["<", "<=", ">", ">=", "=="],
  str: ["=="],
};

export default function SensorInfoFieldsFilter({
  fields,
  initialValues = [],
  values = [],
  setValues,
}) {
  const [checkedColumns, setCheckedColumns] = useState([]);
  const [fieldsDisplayed, setFieldsDisplay] = useState([]);

  useEffect(() => {
    const valueFields = initialValues.map((value) => value.field);
    setCheckedColumns(valueFields);
    setFieldsDisplay(valueFields);
  }, [initialValues]);

  const handleValuesChange = (id, value) => {
    const newValues = [...values];
    newValues[id] = value;
    setValues(newValues);
  };

  const removeValue = (id) => {
    setValues((prevValues) => prevValues.filter((_, idx) => idx !== id));
  };

  const handleOk = () => {
    setFieldsDisplay(checkedColumns);
  };

  const handleCancel = () => {
    setCheckedColumns(fieldsDisplayed);
  };

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        spacing="0.5rem"
        padding="0.5rem 0"
      >
        <Typography variant="button">Filter By Sensor Info Fields</Typography>
        <Popover
          toolTipTitle="Sensor Info Fields"
          toolTipPlacement="top"
          onClose={() => setCheckedColumns(fieldsDisplayed)}
          button={
            <Button variant="text">
              {checkedColumns.length === 0 ? "+ Add Fields" : "Manage Fields"}
            </Button>
          }
        >
          <SelectableList
            title="Sensor Info Fields"
            checked={checkedColumns}
            handleOk={handleOk}
            handleCancel={handleCancel}
            setChecked={setCheckedColumns}
            items={Object.keys(fields)}
          />
        </Popover>
      </Stack>
      {fieldsDisplayed.map((field, idx) => {
        return (
          <Stack
            key={idx}
            direction="row"
            spacing="1rem"
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              style={{ padding: "0.25rem 0", cursor: "pointer" }}
              InputProps={{
                readOnly: true,
              }}
              size="small"
              value={field}
            />
            <FormControl style={{ width: "5rem" }} size="small">
              <Select
                onChange={(e) => {
                  handleValuesChange(idx, {
                    field,
                    value: values[idx] ? values[idx].value : "",
                    operator: e.target.value,
                  });
                }}
                value={values[idx] ? values[idx].operator : "=="}
              >
                {fields.hasOwnProperty(field) ? (
                  TYPE_TO_COMPARISON_OPERATORS[fields[field]].map(
                    (item, idx) => (
                      <MenuItem key={idx} value={item}>
                        {item}
                      </MenuItem>
                    )
                  )
                ) : (
                  <MenuItem value="==">==</MenuItem>
                )}
              </Select>
            </FormControl>
            <TextField
              autoComplete="off"
              onChange={(e) =>
                handleValuesChange(idx, {
                  field,
                  value: e.target.value,
                  operator: values[idx] ? values[idx].operator : "==",
                })
              }
              value={values[idx] ? values[idx].value : ""}
              placeholder=" — "
              size="small"
            />
            <IconButton
              onClick={() => {
                const newFields = fieldsDisplayed.filter(
                  (item) => item !== field
                );
                setFieldsDisplay(newFields);
                setCheckedColumns(newFields);
                removeValue(idx);
              }}
            >
              <CancelIcon />
            </IconButton>
          </Stack>
        );
      })}
    </Box>
  );
}
