import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";

import {
  getAcquisitions,
  tableState,
  pageMetadataState,
  selectedColumns,
} from "../../slices/tableSlice";
import { updateQueryURL } from "../../common/queryURLUtils";
import TableBody from "../../components/Table/TableBody";
import TableToolbar from "../../components/Table/TableToolbar";
import TableHeading from "../../components/Table/TableHeading";

export default function HomePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { acquisitions } = useSelector(tableState);
  const { size, total } = useSelector(pageMetadataState);

  const [filter, setFilter] = useState({});
  const [rows, setRows] = useState(acquisitions.data);
  const [page, setPage] = useState(1);

  const handlePageChange = (_, value) => {
    navigate(updateQueryURL({ ...filter, page: value }));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const newFilter = {
      name: searchParams.get("name") || "",
      description: searchParams.get("description") || "",
      segmentDescription: searchParams.get("segment_description") || "",
      qualityLabels: searchParams.getAll("quality_labels") || [],
      utilityLabels: searchParams.getAll("utility_labels") || [],
      infoFields: searchParams.getAll("info_fields")
        ? searchParams.getAll("info_fields").map((field) => JSON.parse(field))
        : [],
      page: searchParams.get("page") || 1,
    };
    setFilter(newFilter);
    dispatch(getAcquisitions(newFilter));

    // update page number
    setPage(parseInt(newFilter.page));
    setSearchParams(searchParams);
  }, [dispatch, searchParams, setSearchParams]);

  const selectedCols = useSelector(selectedColumns);

  useEffect(() => {
    setRows(acquisitions.data);
  }, [acquisitions]);

  return (
    <div className="page">
      <Paper>
        <TableToolbar filter={filter} />
        <TableContainer sx={{ maxHeight: "calc(100vh - 16.2rem)" }}>
          <Table
            sx={{ minWidth: 500 }}
            stickyHeader
            size="small"
            aria-label="a sticky dense table"
          >
            <TableHeading selectedCols={selectedCols} />
            {acquisitions.loading ? (
              <div style={loadingContainerStyles}>
                <CircularProgress />
              </div>
            ) : (
              <TableBody selectedCols={selectedCols} rows={rows} />
            )}
          </Table>
        </TableContainer>
      </Paper>
      <Stack
        direction="row"
        justifyContent="flex-end"
        style={paginationStyles}
        spacing={2}
      >
        <Pagination
          variant="outlined"
          shape="rounded"
          page={page}
          count={Math.ceil(total / size)}
          defaultPage={1}
          siblingCount={0}
          color="primary"
          onChange={handlePageChange}
        />
      </Stack>
    </div>
  );
}

const paginationStyles = {
  padding: "1rem 0",
};

const loadingContainerStyles = {
  position: "absolute",
  left: "50%",
  top: "50%",
};
