import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import { authState } from "../slices/authSlice";
import { handleIfUnauthorized } from "../common/errorHandlers";
import Api from "../api";
import EditableInputField from "./reusable/EditableInputField";
import LabelsChip from "./LabelsChip";

export default function QualitiesList({ segmentId, items }) {
  const { token } = useSelector(authState);

  const [newQuality, setNewQuality] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [qualities, setQualities] = useState(items);

  const updateSegmentQualities = async (qualitiesToSave) => {
    setLoading(true);
    try {
      const data = await Api.patchSegment(
        segmentId,
        { qualities: qualitiesToSave },
        token
      );
      setData(data);
    } catch (err) {
      handleIfUnauthorized(err);
      setError(err.response ? err.response.data.detail : err.message);
    }
    setLoading(false);
  };

  const handleDelete = (qualityToDelete) => () => {
    const qualitiesToSave = qualities.filter(
      (quality) => quality.id !== qualityToDelete.id
    );
    updateSegmentQualities(qualitiesToSave);
  };

  const handleOk = async () => {
    setError(""); // clear previous errors

    setLoading(true);
    try {
      // Check if the quality exists
      const qualitiesData = await Api.getQualities(
        { label: newQuality, used: "" },
        token
      );
      if (qualitiesData.length !== 1) {
        //  Create the new quality in db
        await Api.postQuality(newQuality, token);
      }
    } catch (err) {
      handleIfUnauthorized(err);
    }
    setLoading(false);

    // reformat the quality for update acquisition qualities
    const qualityToAdd = { label: newQuality };
    const qualitiesToSave = [...qualities, qualityToAdd];
    updateSegmentQualities(qualitiesToSave);
  };

  useEffect(() => {
    if (data && data.qualities) {
      setQualities(data.qualities);
      setNewQuality("");
    }
  }, [data]);

  return (
    <Box sx={boxStyles} component="ul">
      {qualities.map((quality) => (
        <ListItem key={quality.id}>
          <LabelsChip label={quality.label} onDelete={handleDelete(quality)} />
        </ListItem>
      ))}
      <EditableInputField
        placeholder=" ＋ "
        value={newQuality}
        setValue={setNewQuality}
        handleOk={handleOk}
        handleOkOnBlur={false}
        inputProps={{ style: { fontSize: 12 } }}
      />
      {loading && <CircularProgress size={20} sx={loadingStyles} />}
      {error ? <span style={errorStyles}>{error}</span> : null}
    </Box>
  );
}

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const boxStyles = {
  position: "relative",
  display: "flex",
  justifyContent: "left",
  flexWrap: "wrap",
  listStyle: "none",
  p: 0.5,
  m: 0,
};

const loadingStyles = {
  position: "absolute",
  bottom: "-2rem",
};

const errorStyles = {
  position: "absolute",
  bottom: "-2rem",
  margin: 0.5,
  color: "#d32f2f",
  fontSize: "0.75rem",
};
