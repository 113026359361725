import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../api";

export const initialState = {
  token: null,
  loading: false,
  error: null,
};

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (token, { rejectWithValue }) => {
    try {
      const data = await Api.signInGoogleUser(token);
      // TODO: Store the token more securely in the browser (probably in cookie).
      // Look into double submit cookie pattern
      localStorage.setItem("token", data.access_token);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signOut: (state) => {
      localStorage.removeItem("token");
      state.token = initialState.token;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
        state.token = null;
        state.error = null;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.token = null;
        state.error = action.payload
          ? action.payload
          : "Error: Failed to sign in with google.";
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.access_token;
        state.error = null;
      });
  },
});

export const authState = (state) => state.auth;

export const { signOut } = authSlice.actions;

export default authSlice.reducer;
