import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";

export default function LabelsChip({ label, onDelete, props }) {
  return (
    <StyledChip
      {...props}
      size="small"
      label={label}
      deleteIcon={<DeleteIcon />}
      onDelete={onDelete}
    />
  );
}

const StyledChip = styled(Chip)({
  "& .MuiChip-deleteIcon": {
    opacity: 0.2,
  },
  "&:hover": {
    "& .MuiChip-deleteIcon": {
      opacity: 1,
    },
  },
});
