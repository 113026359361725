import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { authState } from "../slices/authSlice";

export default function PrivateRoute({ children }) {
  const { token } = useSelector(authState);
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
}
