import EditableInputField from "./reusable/EditableInputField";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { handleIfUnauthorized } from "../common/errorHandlers";
import { authState } from "../slices/authSlice";
import Api from "../api";

export default function AcquisitionDescriptionInput({ acquisitionId, value }) {
  const { token } = useSelector(authState);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [description, setDescription] = useState(value);
  const [savedDescription, setSavedDescription] = useState(value);

  const handleOk = async () => {
    if (description !== savedDescription) {
      setLoading(true);
      try {
        const data = await Api.patchAcquisition(
          acquisitionId,
          { description },
          token
        );
        setData(data);
      } catch (err) {
        handleIfUnauthorized(err);
        setError(err.response ? err.response.data.detail : err.message);
      }
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setError(null);
  };

  useEffect(() => {
    if (data && data.description) {
      setSavedDescription(data.description);
    }
  }, [data]);
  return (
    <EditableInputField
      multiline={true}
      loading={loading}
      saveError={error}
      value={description}
      setValue={setDescription}
      savedValue={savedDescription}
      handleOk={handleOk}
      handleCancel={handleCancel}
    ></EditableInputField>
  );
}
