import { Fragment, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";

import { CLOUD_STORAGE_PREFIX, JIRA_TICKET_PREFIX } from "../../constants";
import Link from "../reusable/Link";
import Popover from "../reusable/Popover";
import styles from "./TableStyles";
import RowOptions from "../RowOptions";
import AcquisitionDescriptionInput from "../AcquisitionDescriptionInput";
import SegmentDescriptionInput from "../SegmentDescriptionInput";
import TagsList from "../TagsList";
import QualitiesList from "../QualitiesList";
import Sensor from "../Sensor";
import UtilitiesList from "../UtilitiesList";
import { Typography } from "@mui/material";

export default function CustomTableBody({ selectedCols, rows }) {
  // Snackbar logic
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSnackbarClick = () => {
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <>
      <TableBody>
        {rows.map((row) => {
          return (
            <TableRow key={row.id}>
              <TableCell
                align="left"
                style={{
                  ...styles.stickyCellLeft,
                  ...styles.rowNameStyles,
                }}
              >
                <Link
                  href={CLOUD_STORAGE_PREFIX + "raw-acquisitions/" + row.name}
                  target="_blank"
                  rel="noreferrer"
                >
                  {row.name}
                </Link>
              </TableCell>
              {selectedCols.includes("Ticket") && (
                <TableCell align="left" style={styles.rowTicketStyles}>
                  {row.ticket ? (
                    <Link
                      href={JIRA_TICKET_PREFIX + row.ticket}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.ticket}
                    </Link>
                  ) : (
                    " — "
                  )}
                </TableCell>
              )}
              {selectedCols.includes("Team") && (
                <TableCell align="left" style={styles.rowTeamStyles}>
                  <Typography variant="body2">{row.team || " — "}</Typography>
                </TableCell>
              )}
              {selectedCols.includes("Requester Email") && (
                <TableCell align="left" style={styles.rowEmailStyles}>
                  {row.requester_email ? (
                    <Link href={`mailto:${row.requester_email}`}>
                      {row.requester_email}
                    </Link>
                  ) : (
                    " — "
                  )}
                </TableCell>
              )}
              {selectedCols.includes("Tags") && (
                <TableCell align="left" style={styles.rowTagStyles}>
                  <Stack
                    style={{ flexWrap: "wrap", gap: "1rem" }}
                    direction="row"
                  >
                    <TagsList acquisitionId={row.id} items={row.tags || []} />
                  </Stack>
                </TableCell>
              )}
              {selectedCols.includes("Description") && (
                <TableCell align="left" style={styles.rowDescriptionStyles}>
                  <AcquisitionDescriptionInput
                    acquisitionId={row.id}
                    value={row.description}
                  ></AcquisitionDescriptionInput>
                </TableCell>
              )}
              {selectedCols.includes("Radars") && (
                <TableCell align="left" style={styles.rowRadarStyles}>
                  <Stack direction="column" spacing={1}>
                    {row.sensors &&
                      row.sensors
                        .filter((sensor) => sensor.sensor.type === "radar")
                        .map((radar) => {
                          return (
                            <Sensor
                              name={radar.name}
                              serial={radar.sensor.serial}
                              info={radar.info}
                            ></Sensor>
                          );
                        })}
                  </Stack>
                </TableCell>
              )}
              {selectedCols.includes("Cameras") && (
                <TableCell align="left" style={styles.rowCameraStyles}>
                  <Stack direction="column" spacing={1}>
                    {row.sensors &&
                      row.sensors
                        .filter((sensor) => sensor.sensor.type === "camera")
                        .map((camera) => {
                          return (
                            <Sensor
                              name={camera.name}
                              serial={camera.sensor.serial}
                            ></Sensor>
                          );
                        })}
                  </Stack>
                </TableCell>
              )}
              {selectedCols.includes("Lidars") && (
                <TableCell align="left" style={styles.rowLidarStyles}>
                  <Stack direction="column" spacing={1}>
                    {row.sensors &&
                      row.sensors
                        .filter((sensor) => sensor.sensor.type === "lidar")
                        .map((lidar) => {
                          return (
                            <Sensor
                              name={lidar.name}
                              serial={lidar.sensor.serial}
                            ></Sensor>
                          );
                        })}
                  </Stack>
                </TableCell>
              )}
              {selectedCols.some((el) => el.includes("Segment")) && (
                <TableCell
                  style={{ padding: 0, borderLeft: "1px solid #e0e0e0" }}
                >
                  <Table size="small" style={{ height: "100%" }}>
                    <TableBody>
                      {row.segments.map((seg, idx) => (
                        <TableRow
                          key={idx}
                          sx={{
                            "& td": {
                              border: 0,
                              borderBottom: 1,
                              borderColor: "#e0e0e0",
                              paddingTop: 0,
                              paddingBottom: 0,
                            },
                            "&:last-child td": {
                              borderBottom: 0,
                            },
                            "&:first-child td": {
                              borderTop: 0,
                            },
                          }}
                        >
                          {selectedCols.includes("Segment Name") && (
                            <TableCell
                              align="left"
                              style={styles.segNameStyles}
                            >
                              <Link
                                href={CLOUD_STORAGE_PREFIX + seg.path}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {seg.name || " — "}
                              </Link>
                            </TableCell>
                          )}
                          {selectedCols.includes("Segment Path") && (
                            <TableCell
                              align="left"
                              style={styles.segPathStyles}
                            >
                              <Link
                                href={CLOUD_STORAGE_PREFIX + seg.path}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {seg.path || " — "}
                              </Link>
                            </TableCell>
                          )}
                          {selectedCols.includes("Segment Description") && (
                            <TableCell
                              align="left"
                              style={styles.segDescriptionStyles}
                            >
                              <SegmentDescriptionInput
                                segmentId={seg.id}
                                value={seg.description || ""}
                              ></SegmentDescriptionInput>
                            </TableCell>
                          )}
                          {selectedCols.includes("Segment Location") && (
                            <TableCell
                              align="left"
                              style={styles.segLocationStyles}
                            >
                              <Typography variant="body2">
                                {seg.location || " — "}
                              </Typography>
                            </TableCell>
                          )}
                          {selectedCols.includes("Segment Duration") && (
                            <TableCell
                              align="left"
                              style={styles.segDurationStyles}
                            >
                              <Typography variant="body2">
                                {seg.duration || " — "}
                              </Typography>
                            </TableCell>
                          )}
                          {selectedCols.includes("Segment Distance") && (
                            <TableCell
                              align="left"
                              style={styles.segDistanceStyles}
                            >
                              <Typography variant="body2">
                                {seg.distance || " — "}
                              </Typography>
                            </TableCell>
                          )}
                          {selectedCols.includes("Segment Quality Labels") && (
                            <TableCell
                              align="left"
                              style={styles.segQualitiesStyles}
                            >
                              <Stack direction="row" spacing={1}>
                                <QualitiesList
                                  segmentId={seg.id}
                                  items={seg.qualities || []}
                                />
                              </Stack>
                            </TableCell>
                          )}
                          {selectedCols.some((el) => el.includes("Job")) && (
                            <TableCell style={{ padding: 0 }}>
                              <Table size="small" style={{ height: "100%" }}>
                                <TableBody>
                                  {seg.jobs.length > 0 ? (
                                    seg.jobs.map((job) => {
                                      return (
                                        <TableRow
                                          key={job.id}
                                          sx={{
                                            "& td": {
                                              borderBottom: 0,
                                              paddingTop: 0,
                                              paddingBottom: 0,
                                            },
                                          }}
                                        >
                                          {selectedCols.includes(
                                            "Job Type"
                                          ) && (
                                            <TableCell
                                              align="left"
                                              style={styles.jobTypeStyles}
                                            >
                                              <Typography
                                                variant="body1"
                                                sytle={{
                                                  wordBreak: "break-word",
                                                }}
                                              >
                                                {job.job_type.name || " — "}
                                              </Typography>
                                            </TableCell>
                                          )}
                                          {selectedCols.includes(
                                            "Job Date"
                                          ) && (
                                            <TableCell
                                              align="left"
                                              style={styles.jobDateStyles}
                                            >
                                              <Typography variant="body1">
                                                {job.date || " — "}
                                              </Typography>
                                            </TableCell>
                                          )}
                                          {selectedCols.includes(
                                            "Job Git Sha"
                                          ) && (
                                            <TableCell
                                              align="left"
                                              style={styles.jobGitShaStyles}
                                            >
                                              <Typography
                                                style={{
                                                  wordBreak: "break-word",
                                                }}
                                                variant="body1"
                                              >
                                                {job.git_sha || " — "}
                                              </Typography>
                                            </TableCell>
                                          )}
                                          {selectedCols.includes(
                                            "Job Output Path"
                                          ) && (
                                            <TableCell
                                              align="left"
                                              style={styles.jobPathStyles}
                                            >
                                              <Typography variant="body1">
                                                {job.output_path || " — "}
                                              </Typography>
                                            </TableCell>
                                          )}
                                          {selectedCols.includes(
                                            "Job Utility Labels"
                                          ) && (
                                            <TableCell
                                              align="left"
                                              style={styles.segQualitiesStyles}
                                            >
                                              <Stack
                                                direction="row"
                                                spacing={1}
                                              >
                                                <UtilitiesList
                                                  jobId={job.id}
                                                  items={job.utilities || []}
                                                />
                                              </Stack>
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      );
                                    })
                                  ) : (
                                    <TableRow sx={{ "& td": { border: 0 } }}>
                                      {selectedCols.includes("Job Type") && (
                                        <TableCell
                                          align="left"
                                          style={styles.jobTypeStyles}
                                        >
                                          <span style={styles.blankFieldStyles}>
                                            {" — "}
                                          </span>
                                        </TableCell>
                                      )}
                                      {selectedCols.includes("Job Date") && (
                                        <TableCell
                                          align="left"
                                          style={styles.jobTypeStyles}
                                        >
                                          <span style={styles.blankFieldStyles}>
                                            {" — "}
                                          </span>
                                        </TableCell>
                                      )}
                                      {selectedCols.includes("Job Git Sha") && (
                                        <TableCell
                                          align="left"
                                          style={styles.jobGitShaStyles}
                                        >
                                          <span style={styles.blankFieldStyles}>
                                            {" — "}
                                          </span>
                                        </TableCell>
                                      )}
                                      {selectedCols.includes(
                                        "Job Output Path"
                                      ) && (
                                        <TableCell
                                          align="left"
                                          style={styles.jobPathStyles}
                                        >
                                          <span style={styles.blankFieldStyles}>
                                            {" — "}
                                          </span>
                                        </TableCell>
                                      )}
                                      {selectedCols.includes(
                                        "Job Utility Labels"
                                      ) && (
                                        <TableCell
                                          align="left"
                                          style={styles.segQualitiesStyles}
                                        >
                                          <span style={styles.blankFieldStyles}>
                                            {" — "}
                                          </span>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableCell>
              )}
              <TableCell
                style={{ ...styles.stickyCellRight, ...styles.optionStyles }}
                align="center"
              >
                <Popover
                  toolTipTitle="Open options menu"
                  toolTipPlacement="left"
                  button={
                    <IconButton>
                      <MoreVertIcon
                        style={{ color: "#000", width: "1.3rem" }}
                      />
                    </IconButton>
                  }
                >
                  <RowOptions
                    handleSnackbarClick={handleSnackbarClick}
                    rowName={row.name}
                  />
                </Popover>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="Acquisition URL copied to clipboard"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        action={action}
      />
    </>
  );
}
