const stickyCell = {
  background: "white",
  position: "sticky",
  zIndex: 5,
};

const stickyCellLeft = {
  ...stickyCell,
  left: 0,
  borderRight: "1px solid #e0e0e0",
};

const stickyCellRight = {
  ...stickyCell,
  right: 0,
  borderLeft: "1px solid #e0e0e0",
};

const cell = {
  // use this for applying styles to all table
  // cells
};

const styles = {
  stickyCellLeft,
  stickyCellRight,
  cellHeadingDivStyles: {
    display: "flex",
    alignItems: "center",
    gap: "0.2rem",
  },
  rowNameStyles: {
    ...cell,
    minWidth: "7rem",
    width: "7rem",
  },
  rowTicketStyles: {
    ...cell,
    minWidth: "4rem",
    width: "4rem",
  },
  rowTeamStyles: {
    ...cell,
    minWidth: "4rem",
    width: "4rem",
  },
  rowEmailStyles: {
    ...cell,
    minWidth: "6rem",
    width: "6rem",
  },
  rowTagStyles: {
    ...cell,
    minWidth: "16rem",
    width: "16rem",
  },
  rowDescriptionStyles: {
    ...cell,
    minWidth: "20rem",
    width: "20rem",
  },
  rowRadarStyles: {
    ...cell,
    minWidth: "9rem",
    width: "9rem",
  },
  rowCameraStyles: {
    ...cell,
    minWidth: "6rem",
    width: "6rem",
  },
  rowLidarStyles: {
    ...cell,
    minWidth: "4rem",
    width: "4rem",
  },
  segNameStyles: {
    ...cell,
    minWidth: "7rem",
    width: "7rem",
    textAlign: "center",
  },
  segPathStyles: {
    ...cell,
    minWidth: "16rem",
    width: "16rem",
  },
  segDescriptionStyles: {
    ...cell,
    minWidth: "18rem",
    width: "18rem",
  },
  segLocationStyles: {
    ...cell,
    minWidth: "18rem",
    width: "18rem",
  },
  segDurationStyles: {
    ...cell,
    minWidth: "8rem",
    width: "8rem",
    textAlign: "center",
  },
  segDistanceStyles: {
    ...cell,
    minWidth: "8rem",
    width: "8rem",
    textAlign: "center",
  },
  segQualitiesStyles: {
    ...cell,
    minWidth: "16rem",
    width: "16rem",
  },
  jobTypeStyles: {
    ...cell,
    minWidth: "12rem",
    width: "12rem",
  },
  jobDateStyles: {
    ...cell,
    minWidth: "10rem",
    width: "10rem",
  },
  jobGitShaStyles: {
    ...cell,
    minWidth: "13rem",
    width: "13rem",
  },
  jobPathStyles: {
    ...cell,
    minWidth: "35rem",
    width: "35rem",
  },
  optionStyles: {
    ...cell,
    minWidth: "3rem",
    width: "3rem",
  },
  blankFieldStyles: {
    opacity: 0.3,
  },
  filterModalStyles: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 5,
    padding: "0 0.5rem",
  },
};

export default styles;
