import Link from "@mui/material/Link";
import LaunchIcon from "@mui/icons-material/Launch";
import { useState } from "react";

export default function CusomLink({ children, endComponent, ...props }) {
  const [isIconVisible, setIsIconVisible] = useState(false);
  return (
    <Link
      sx={{ position: "relative" }}
      onMouseEnter={() => setIsIconVisible(true)}
      onMouseLeave={() => setIsIconVisible(false)}
      {...props}
    >
      {children}
      {isIconVisible && (
        <LaunchIcon sx={{ width: "0.5rem", position: "absolute", bottom: 0 }} />
      )}
    </Link>
  );
}
