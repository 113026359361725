import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Button, Divider, Typography } from "@mui/material";
import { useState } from "react";

export default function SelectableList(props) {
  const {
    title,
    setAnchorEl,
    checked,
    setChecked,
    items,
    handleCancel = () => {},
    handleOk = () => {},
  } = props;

  const [areAllSelected, setAreAllSelected] = useState(
    checked.length === items.length
  );

  const handleToggle = (item) => () => {
    const currentIndex = checked.indexOf(item);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length === items.length) {
      setAreAllSelected(true);
    }

    setChecked(newChecked);
  };

  const handleSelectAll = () => {
    if (items.length === checked.length) {
      setChecked([]);
      setAreAllSelected(false);
    } else {
      setChecked(items);
      setAreAllSelected(true);
    }
  };

  const handleListCancel = () => {
    handleCancel();
    setAnchorEl(null);
  };

  const handleListOk = () => {
    handleOk();
    setAnchorEl(null);
  };

  return (
    <List
      sx={{ bgcolor: "background.paper", paddingTop: 0, paddingBottom: 0 }}
      dense={true}
    >
      <div style={{ ...stickyDivStyles, top: "0" }}>
        <ListItem>
          <Typography variant="subtitle2">{title}</Typography>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton role={undefined} onClick={handleSelectAll} dense>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={areAllSelected}
                indeterminate={
                  checked.length !== items.length && checked.length !== 0
                }
                disableRipple
                inputProps={{ "aria-labelledby": "all selected" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                items.length === checked.length
                  ? `All selected`
                  : areAllSelected
                  ? `${checked.length} of ${items.length}
                     selected`
                  : "Select All"
              }
            />
          </ListItemButton>
        </ListItem>
        <Divider />
      </div>
      {items.map((item) => {
        const labelId = `checkbox-list-label-${item}`;
        return (
          <ListItem key={item} disablePadding>
            <ListItemButton role={undefined} onClick={handleToggle(item)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(item) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item} />
            </ListItemButton>
          </ListItem>
        );
      })}
      <div style={{ ...stickyDivStyles, bottom: "0" }}>
        <Divider />
        <ListItem sx={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={handleListCancel} variant="text">
            CANCEL
          </Button>
          <Button onClick={handleListOk} variant="text">
            OK
          </Button>
        </ListItem>
      </div>
    </List>
  );
}

const stickyDivStyles = {
  position: "sticky",
  background: "white",
  padding: "0.5rem 0",
  zIndex: 5,
};
