import { useState, cloneElement } from "react";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";

export default function CustomPopover({
  toolTipTitle = "",
  toolTipPlacement = "left",
  button: Button,
  iconStyles = {},
  onClose = () => {},
  onClick = () => {},
  children,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  return (
    <>
      <Tooltip title={toolTipTitle} placement={toolTipPlacement}>
        {cloneElement(Button, { onClick: handleClick })}
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        {...props}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {cloneElement(children, { setAnchorEl })}
      </Popover>
    </>
  );
}
