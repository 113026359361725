import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";

import {
  getQualities,
  getUtilities,
  getSensorInfoFields,
  tableState,
  updateSelectedColumns,
} from "../../slices/tableSlice";
import { updateQueryURL } from "../../common/queryURLUtils";
import styles from "./TableStyles";
import Popover from "../reusable/Popover";
import SelectableList from "../reusable/SelectableList";
import SensorInfoFieldsFilter from "../SensorInfoFieldsFilter";

export default function TableToolbar({ filter }) {
  const dispatch = useDispatch();
  const { columns, selectedColumns, qualities, utilities, sensorInfoFields } =
    useSelector(tableState);

  const navigate = useNavigate();

  const [checkedColumns, setCheckedColumns] = useState(selectedColumns);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [segmentDescription, setSegmentDescription] = useState("");
  const [qualityLabels, setQualityLabels] = useState([]);
  const [utilityLabels, setUtilityLables] = useState([]);
  const [infoFields, setInfoFields] = useState([]);

  useEffect(() => {
    dispatch(getQualities({ used: true }));
    dispatch(getUtilities({ used: true }));
    dispatch(getSensorInfoFields({}));
  }, [dispatch]);

  useEffect(() => {
    setName(filter.name || "");
    setDescription(filter.description || "");
    setSegmentDescription(filter.segmentDescription || "");
    setQualityLabels(filter.qualityLabels || []);
    setUtilityLables(filter.utilityLabels || []);
    setInfoFields(filter.infoFields || []);
  }, [filter]);

  const handleListCancel = () => {
    setCheckedColumns(selectedColumns);
  };

  const handleListOk = () => {
    dispatch(updateSelectedColumns(checkedColumns));
    localStorage.setItem("selectedColumns", JSON.stringify(checkedColumns));
  };

  const handleFilterModalClick = () => {
    setOpenFilterModal(true);
    dispatch(getQualities({ used: true }));
    dispatch(getUtilities({ used: true }));
    dispatch(getSensorInfoFields({}));
  };

  const handleFilterModalClose = () => setOpenFilterModal(false);

  const handleFilterModalOk = () => {
    const updatedURL = updateQueryURL({
      name,
      description,
      segmentDescription,
      qualityLabels,
      utilityLabels,
      infoFields,
      page: 1,
    });
    setOpenFilterModal(false);
    navigate(updatedURL);
  };

  const handleFilterModalCancel = () => {
    setOpenFilterModal(false);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        position: "relative",
        borderBottom: 1,
        borderColor: "grey.300",
      }}
    >
      <Popover
        toolTipTitle="Column display options"
        toolTipPlacement="top"
        button={
          <IconButton>
            <ViewColumnIcon style={{ color: "#000", width: "1.3rem" }} />
          </IconButton>
        }
      >
        <SelectableList
          title="Displayed Columns"
          checked={checkedColumns}
          setChecked={setCheckedColumns}
          handleCancel={handleListCancel}
          handleOk={handleListOk}
          items={columns}
        />
      </Popover>
      <Tooltip title="Filter options" placement="top">
        <Button
          style={{ color: "#000" }}
          onClick={handleFilterModalClick}
          startIcon={
            <FilterListIcon style={{ color: "#000", width: "1.3rem" }} />
          }
        >
          Filter
        </Button>
      </Tooltip>
      <Stack direction="row" spacing={1}>
        {filter.name && (
          <TextField
            size="small"
            label="Acquisition"
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate(updateQueryURL({ ...filter, name: name }));
              }
            }}
            onBlur={() => navigate(updateQueryURL({ ...filter, name }))}
            InputProps={{
              sx: { width: "12rem" },
              endAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Drop filter">
                    <IconButton
                      onClick={() => {
                        setName("something");
                        navigate(updateQueryURL({ ...filter, name: "" }));
                      }}
                    >
                      <ClearIcon style={{ width: "0.8rem" }} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            defaultValue={filter.name}
            value={name}
          />
        )}
        {filter.description && (
          <TextField
            size="small"
            label="Acquisition Description"
            onChange={(e) => setDescription(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate(updateQueryURL({ ...filter, description }));
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Drop filter">
                    <IconButton
                      onClick={() => {
                        setDescription("");
                        navigate(
                          updateQueryURL({
                            ...filter,
                            description: "",
                          })
                        );
                      }}
                    >
                      <ClearIcon style={{ width: "0.8rem" }} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            defaultValue={filter.description}
            value={description}
          />
        )}
        {filter.segmentDescription && (
          <TextField
            size="small"
            label="Segment Description"
            onChange={(e) => setSegmentDescription(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate(updateQueryURL({ ...filter, segmentDescription }));
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Drop filter">
                    <IconButton
                      onClick={() => {
                        setSegmentDescription("");
                        navigate(
                          updateQueryURL({
                            ...filter,
                            segmentDescription: "",
                          })
                        );
                      }}
                    >
                      <ClearIcon style={{ width: "0.8rem" }} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            defaultValue={filter.segmentDescription}
            value={segmentDescription}
          />
        )}
        {filter.qualityLabels && filter.qualityLabels.length > 0 && (
          <Autocomplete
            multiple
            size="small"
            limitTags={2}
            options={qualities.data.map((quality) => quality.label)}
            value={qualityLabels}
            filterSelectedOptions
            onChange={(e, labels) => {
              navigate(updateQueryURL({ ...filter, qualityLabels: labels }));
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Quality Labels" />
            )}
          />
        )}
        {filter.utilityLabels && filter.utilityLabels.length > 0 && (
          <Autocomplete
            multiple
            size="small"
            limitTags={2}
            options={utilities.data.map((utility) => utility.label)}
            value={utilityLabels}
            filterSelectedOptions
            onChange={(e, labels) => {
              navigate(updateQueryURL({ ...filter, utilityLabels: labels }));
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Utility Labels" />
            )}
          />
        )}
        {filter.infoFields && filter.infoFields.length > 0 && (
          <Stack direction="row" spacing="0.5rem">
            {filter.infoFields.map((item, id) => (
              <Chip
                variant="outlined"
                label={`${item.field} ${item.operator} ${item.value}`}
                onDelete={() => {
                  const newInfoFields = infoFields.filter(
                    (_, idx) => idx !== id
                  );
                  navigate(
                    updateQueryURL({ ...filter, infoFields: newInfoFields })
                  );
                }}
              ></Chip>
            ))}
          </Stack>
        )}
      </Stack>
      <Modal
        open={openFilterModal}
        onClose={handleFilterModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.filterModalStyles}>
          <Box sx={{ minHeight: 400, overflowY: "auto", padding: "0.5rem" }}>
            <Typography id="modal-modal-title" variant="button">
              Filter
            </Typography>
            <Typography variant="body2" display="block">
              {" "}
              The text filters are applied using a LIKE operation - use{" "}
              <a
                href="https://www.w3schools.com/mysql/mysql_wildcards.asp"
                target="_blank"
                rel="noreferrer"
              >
                wildcards
              </a>{" "}
              to match substrings.
            </Typography>
            <br />
            <Stack spacing={2}>
              <TextField
                size="small"
                label="Acquisition"
                value={name}
                onChange={(e) => setName(e.target.value)}
                helperText="Does a match on acquisition name"
                variant="outlined"
              ></TextField>
              <TextField
                size="small"
                label="Acquisition Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                helperText="Checks if substring is in the acquisition description"
                variant="outlined"
              ></TextField>
              <TextField
                size="small"
                label="Segment Description"
                value={segmentDescription}
                onChange={(e) => setSegmentDescription(e.target.value)}
                helperText="Checks if substring is in the segment description"
                variant="outlined"
              ></TextField>
              <Box>
                <FormControl fullWidth size="small">
                  <Autocomplete
                    size="small"
                    multiple
                    options={qualities.data.map((quality) => quality.label)}
                    value={qualityLabels}
                    filterSelectedOptions
                    onChange={(e, labels) => {
                      setQualityLabels(labels);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Quality Labels" />
                    )}
                  />
                  <FormHelperText>
                    Filter based on quality labels
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth size="small">
                  <Autocomplete
                    size="small"
                    multiple
                    options={utilities.data.map((utility) => utility.label)}
                    value={utilityLabels}
                    filterSelectedOptions
                    onChange={(e, labels) => {
                      setUtilityLables(labels);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Utility Labels" />
                    )}
                  />
                  <FormHelperText>
                    Filter based on utility labels
                  </FormHelperText>
                </FormControl>
              </Box>
              <SensorInfoFieldsFilter
                initialValues={filter.infoFields}
                values={infoFields}
                setValues={setInfoFields}
                fields={sensorInfoFields.data
                  .filter((field) =>
                    ["int", "float", "str"].includes(field.type)
                  )
                  .reduce(
                    (o, field) =>
                      Object.assign(o, { [field.field]: field.type }),
                    {}
                  )}
              />
            </Stack>
          </Box>
          <Divider />
          <Stack
            direction="row"
            spacing="1rem"
            justifyContent="flex-end"
            style={{ padding: "0.5rem 0" }}
          >
            <Button onClick={handleFilterModalCancel} variant="text">
              Cancel
            </Button>
            <Button onClick={handleFilterModalOk} variant="text">
              OK
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Toolbar>
  );
}
