import { configureStore } from "@reduxjs/toolkit";
import tableReducer from "../slices/tableSlice";
import authReducer from "../slices/authSlice";
import userReducer from "../slices/userSlice";
import { initialState as tableInitialState } from "../slices/tableSlice";

export const store = configureStore({
  preloadedState: {
    auth: {
      token: localStorage.getItem("token") || null,
    },
    table: {
      ...tableInitialState,
      selectedColumns: localStorage.getItem("selectedColumns")
        ? JSON.parse(localStorage.getItem("selectedColumns"))
        : tableInitialState.columns,
    },
  },
  reducer: {
    table: tableReducer,
    auth: authReducer,
    user: userReducer,
  },
});
