import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NET from "vanta/dist/vanta.net.min";

import { authState, signIn } from "../../slices/authSlice";
import styles from "./LoginPage.module.css";

const GAUTH_CLIENT_ID =
  "113769461746-etih22b37rfpqmteoppg6f9tpvgloool" +
  ".apps.googleusercontent.com";

export default function LoginPage() {
  const [googleUserCredentials, setGoogleUserCredentials] = useState("");
  const [vantaEffect, setVantaEffect] = useState(null);

  const navigate = useNavigate("");

  const { token } = useSelector(authState);

  const myRef = useRef(null);

  // If there is an access token, we simply navigate to the Home Page
  // TODO: Check if the access token has expired or not before navigating
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  // Load the google sign in libraries
  useEffect(() => {
    const attachCredentialResponseHandler = () => {
      window.responseHandler = (response) => {
        setGoogleUserCredentials(response);
      };
    };
    const loadGoogleSignInLibrary = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.id = "sign-in-script";
      document.body.appendChild(script);

      return script;
    };
    attachCredentialResponseHandler();
    const script = loadGoogleSignInLibrary();

    // Cleanup when component is unmounted
    return () => {
      document.responseHandler = null;
      document.body.removeChild(script);
    };
  }, []);

  const dispatch = useDispatch();
  // If we receive a response from google-sign-in we send the credentials
  // to the api. A successful response would mean we get a new access
  // token at which point we navigate to the home page
  useEffect(() => {
    if (googleUserCredentials) {
      dispatch(signIn(googleUserCredentials));
    }
  }, [dispatch, googleUserCredentials]);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        NET({
          el: myRef.current,
          color: "#24cc58",
          backgroundColor: "#000",
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div ref={myRef} className={styles.background}>
      <img
        className={styles.datahub_text}
        src="https://storage.googleapis.com/acquisitions-250323.appspot.com/datahub.png"
        alt="DataHub Text"
      />
      <div className={styles.google_button}>
        <div
          id="g_id_onload"
          data-client_id={GAUTH_CLIENT_ID}
          data-context="signin"
          data-callback="responseHandler"
        ></div>
      </div>
    </div>
  );
}
