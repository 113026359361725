import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import Popover from "./reusable/Popover";

export default function Sensor({ name, serial, info }) {
  return (
    <Box sx={{ boxShadow: 1, padding: "0.1rem" }}>
      <List dense>
        <ListItem style={serialInfoContainerStyles}>
          <Typography variant="subtitle2">{serial}</Typography>
          <Typography variant="caption">{`(${name})`}</Typography>
          {info && (
            <Popover
              toolTipTitle="Sensor additional info"
              button={
                <Chip
                  style={chipStyles}
                  label="info"
                  variant="outlined"
                  size="small"
                ></Chip>
              }
            >
              <Box style={{ padding: "0.5rem" }}>
                {Object.entries(info).map(([key, value]) => (
                  <li key={key} style={infoListStyles}>
                    <b>{key}</b>: {value}
                  </li>
                ))}
              </Box>
            </Popover>
          )}
        </ListItem>
      </List>
    </Box>
  );
}

const serialInfoContainerStyles = {
  padding: "0.2rem 0.35rem",
  display: "flex",
  gap: "0.1rem",
  alignItems: "center",
  justifyContent: "space-between",
};

const chipStyles = {
  borderRadius: 0,
};

const infoListStyles = {
  padding: "0.2rem",
};
