import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { authState } from "../slices/authSlice";
import { handleIfUnauthorized } from "../common/errorHandlers";
import Api from "../api";
import EditableInputField from "./reusable/EditableInputField";
import LabelsChip from "./LabelsChip";

export default function TagsList({ acquisitionId, items }) {
  const { token } = useSelector(authState);

  const [newTag, setNewTag] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [tags, setTags] = useState(items);

  const updateAcquisitionTags = async (tagsToSave) => {
    setLoading(true);
    try {
      const data = await Api.patchAcquisition(
        acquisitionId,
        { tags: tagsToSave },
        token
      );
      setData(data);
    } catch (err) {
      handleIfUnauthorized(err);
      setError(err.response ? err.response.data.detail : err.message);
    }
    setLoading(false);
  };

  const handleDelete = (tagToDelete) => () => {
    const tagsToSave = tags.filter((tag) => tag.id !== tagToDelete.id);
    updateAcquisitionTags(tagsToSave);
  };

  const handleOk = async () => {
    setError(""); // clear previous errors

    setLoading(true);
    try {
      // Check if the tag exists
      const tagsData = await Api.getTags(newTag, token);
      if (tagsData.length !== 1) {
        //  Create the new tag in db
        await Api.postTag(newTag, token);
      }
    } catch (err) {
      handleIfUnauthorized(err);
    }
    setLoading(false);

    // reformat the tag for update acquisition tags
    const tagToAdd = { label: newTag };
    const tagsToSave = [...tags, tagToAdd];
    updateAcquisitionTags(tagsToSave);
  };

  useEffect(() => {
    if (data && data.tags) {
      setTags(data.tags);
      setNewTag("");
    }
  }, [data]);

  return (
    <Box sx={boxStyles} component="ul">
      {tags.map((tag) => (
        <ListItem key={tag.id}>
          <LabelsChip label={tag.label} onDelete={handleDelete(tag)} />
        </ListItem>
      ))}
      <EditableInputField
        placeholder=" ＋ "
        value={newTag}
        setValue={setNewTag}
        handleOk={handleOk}
        handleOkOnBlur={false}
        inputProps={{ style: { fontSize: 12 } }}
      />
      {loading && <CircularProgress size={20} sx={loadingStyles} />}
      {error ? <span style={errorStyles}>{error}</span> : null}
    </Box>
  );
}

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const boxStyles = {
  position: "relative",
  display: "flex",
  justifyContent: "left",
  flexWrap: "wrap",
  listStyle: "none",
  p: 0.5,
  m: 0,
};

const loadingStyles = {
  position: "absolute",
  bottom: "-2rem",
};

const errorStyles = {
  position: "absolute",
  bottom: "-2rem",
  margin: 0.5,
  color: "#d32f2f",
  fontSize: "0.75rem",
};
