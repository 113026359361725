import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function RowOptions({
  rowName = "",
  setAnchorEl = () => {},
  handleSnackbarClick = () => {},
}) {
  const location = window.location;
  return (
    <List dense>
      <ListItemButton
        onClick={() => {
          navigator.clipboard.writeText(
            `${location.protocol + location.host}?name=${rowName}`
          );
          setAnchorEl(null);
          handleSnackbarClick();
        }}
      >
        <ListItemIcon>
          <ContentCopyIcon />
        </ListItemIcon>
        <ListItemText primary="Copy URL" />
      </ListItemButton>
    </List>
  );
}
