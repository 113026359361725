import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import { authState } from "../slices/authSlice";
import { handleIfUnauthorized } from "../common/errorHandlers";
import Api from "../api";
import EditableInputField from "./reusable/EditableInputField";
import LabelsChip from "./LabelsChip";

export default function UtilitiesList({ jobId, items }) {
  const { token } = useSelector(authState);

  const [newUtility, setNewUtility] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [utilities, setUtilities] = useState(items);

  const updateSegmentUtilities = async (utilitiesToSave) => {
    setLoading(true);
    try {
      const data = await Api.patchJob(
        jobId,
        { utilities: utilitiesToSave },
        token
      );
      setData(data);
    } catch (err) {
      handleIfUnauthorized(err);
      setError(err.response ? err.response.data.detail : err.message);
    }
    setLoading(false);
  };

  const handleDelete = (utilityToDelete) => () => {
    const utilitiesToSave = utilities.filter(
      (utility) => utility.id !== utilityToDelete.id
    );
    updateSegmentUtilities(utilitiesToSave);
  };

  const handleOk = async () => {
    setError(""); // clear previous errors

    setLoading(true);
    try {
      // Check if the utility exists
      const utilitiesData = await Api.getUtilities(
        { label: newUtility, used: "" },
        token
      );
      if (utilitiesData.length !== 1) {
        //  Create the new utility in db
        await Api.postUtility(newUtility, token);
      }
    } catch (err) {
      handleIfUnauthorized(err);
    }
    setLoading(false);

    // reformat the utility for update acquisition utilities
    const utilityToAdd = { label: newUtility };
    const utilitiesToSave = [...utilities, utilityToAdd];
    updateSegmentUtilities(utilitiesToSave);
  };

  useEffect(() => {
    if (data && data.utilities) {
      setUtilities(data.utilities);
      setNewUtility("");
    }
  }, [data]);

  return (
    <Box sx={boxStyles} component="ul">
      {utilities.map((utility) => (
        <ListItem key={utility.id}>
          <LabelsChip label={utility.label} onDelete={handleDelete(utility)} />
        </ListItem>
      ))}
      <EditableInputField
        placeholder=" ＋ "
        value={newUtility}
        setValue={setNewUtility}
        handleOk={handleOk}
        handleOkOnBlur={false}
        inputProps={{ style: { fontSize: 12 } }}
      />
      {loading && <CircularProgress size={20} sx={loadingStyles} />}
      {error ? <span style={errorStyles}>{error}</span> : null}
    </Box>
  );
}

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const boxStyles = {
  position: "relative",
  display: "flex",
  justifyContent: "left",
  flexWrap: "wrap",
  listStyle: "none",
  p: 0.5,
  m: 0,
};

const loadingStyles = {
  position: "absolute",
  bottom: "-2rem",
};

const errorStyles = {
  position: "absolute",
  bottom: "-2rem",
  margin: 0.5,
  color: "#d32f2f",
  fontSize: "0.75rem",
};
