import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import { authState, signOut } from "../slices/authSlice";
import {
  getUserProfile,
  clearUserProfile,
  userState,
} from "../slices/userSlice";
import { updateQueryURL } from "../common/queryURLUtils";

export default function Header() {
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { token: signedIn } = useSelector(authState);
  const { user } = useSelector(userState);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(signOut());
  };

  useEffect(() => {
    if (!user && signedIn) {
      dispatch(getUserProfile({}));
    }
    return () => {
      dispatch(clearUserProfile());
    };
  }, [dispatch, user, signedIn]);

  return (
    <AppBar style={appBarStyles}>
      <Container maxWidth="fixed">
        <Toolbar sx={toolBarStyles} disableGutters>
          <Box sx={{ boxShadow: 3 }}>
            <nav>
              <Link to={user ? updateQueryURL({ page: 1 }) : "#"}>
                <img
                  style={logoStyles}
                  src="https://storage.googleapis.com/acquisitions-250323.appspot.com/logo192.png"
                  alt="zendar-logo"
                />
              </Link>
            </nav>
          </Box>
          <Box>
            {!signedIn && (
              <div
                className="g_id_signin"
                data-type="standard"
                data-size="large"
                data-theme="outline"
                data-text="sign_in_with"
                data-shape="rectangular"
                data-logo_alignment="left"
              ></div>
            )}
            {signedIn && user && (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src={user.picture} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography onClick={handleLogOut} textAlign="center">
                      Logout
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const appBarStyles = {
  background: "#00BA5F",
};

const toolBarStyles = {
  display: "flex",
  justifyContent: "space-between",
};

const logoStyles = {
  width: "8rem",
};
