import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { handleIfUnauthorized } from "../common/errorHandlers";
import Api from "../api";

export const initialState = {
  user: null,
  loading: false,
  error: null,
};

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async (_, { rejectWithValue, getState }) => {
    const {
      auth: { token },
    } = getState();
    try {
      const data = await Api.getCurrentUser(token);
      return data;
    } catch (err) {
      handleIfUnauthorized(err);
      return rejectWithValue(
        err.response ? err.response.data.detail : err.message
      );
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserProfile: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.error = action.payload
          ? action.payload
          : "Error: Failed to fetch profile info.";
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      });
  },
});

export const userState = (state) => state.user;

export const { clearUserProfile } = userSlice.actions;

export default userSlice.reducer;
